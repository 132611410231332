import React from 'react'
import GeoHeader from './geoHeader'
import GeoFooter from './geoFooter'
import top from "./../../images/thegeorgie/icon-top.webp";
import bottom from "./../../images/thegeorgie/icon-bottom.webp";
import Accordion from 'react-bootstrap/Accordion';

function Floorplans() {
    return (
        <>
            <main id="thegeorgie" className='inner_header'>
                <GeoHeader logoWhite={false}  menuName="Floorplans"/>
                <main>
                    <section className='geo-banner geo-page-banner'>
                        <div className='container'>
                            <div className='geo-banner__content'>
                                <img className='top_icon' src={top} />
                                <h1>Floorplans</h1>
                                <img className='bottom_icon' src={bottom} />
                            </div>
                        </div>


                    </section>

                    <section className='sec-padd features-page'>
                        <div className='container'>
                            <div className='row g-md-5 g-4'>
                                <div className='col-md-4'>
                                    <h2>Features + Finishes</h2>
                                </div>
                                <div className='col-md-8'>

                                    <Accordion defaultActiveKey="0" alwaysOpen>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Keaton</Accordion.Header>
                                            <Accordion.Body>
                                            <p><a href="#">1 BEDROOM / 1 BATH / <strong>804 SQFT</strong></a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Chaplin A</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + 1 DEN / 1 BATH / 859 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Chaplin B</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + 1 DEN / 1 BATH / 943 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Fairbanks</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>2 BEDROOMS + 1 DEN / 2 BATHS / 1,203 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Gatsby</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>2 BEDROOMS / 2 BATHS / 1,551 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Philbin</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + DEN / 1.5 BATHS / 1,057 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>Davies</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + DEN / 1 BATH / 798 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>Ralston</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>2 BEDROOMS / 2 BATHS / 954 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>Gilbert</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM / 1 BATH / 684 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header>Gaynor</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>2 BEDROOMS / 2 BATHS / 954 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header>Garbo</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>2 BEDROOMS / 2 BATHS / 995 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header>Brooks</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + 1 DEN / 1 BATH / 859 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="12">
                                            <Accordion.Header>Chaney</Accordion.Header>
                                            <Accordion.Body>
                                               <p><a href='#'>1 BEDROOM + DEN / 1 BATH / 801 SQFT</a></p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>




                                </div>
                            </div>

                        </div>

                    </section>






                </main>

                <GeoFooter />
            </main>
        </>
    )
}

export default Floorplans