import React, { useState, useEffect ,useRef} from 'react';
import "./geo.css";

import logo2 from "./../../images/thegeorgie/TheGeorgie_logo2.webp";
import dots from "./../../images/thegeorgie/dots.webp";
import pageimg from "./../../images/thegeorgie/TheGeorgie_Pages.webp";
import glogo from "./../../images/thegeorgie/TheGeorgie_WEBL.webp";

import GeoHeader from './geoHeader';
import GeoFooter from './geoFooter';
import ContactUsGeorgie from './ContactUsGeorgie';







const TheGeorgieWebsite = () => {
  const contactRef = useRef(null);

  const handleScroll = (ref, name) => {

    const offset = 100;
    const sectionPosition =
      ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });

  };
  
  return (
    <main id="thegeorgie">
     <GeoHeader  
      logoWhite  contactRef={contactRef} handlePage={handleScroll}  />


  <main>
      <section className="banner_sec1">
        <div className="container">
          <div className="text-center">
            <img src={logo2} />
          </div>
        </div>
        <img className="img_sec1" src={dots} />
      </section>
      <section className="vedio-section">

        <iframe width="100%" height="854" src="https://www.youtube.com/embed/yYGh4276rkU?autoplay=1&mute=1&loop=1" title="Crescent Homes - The Georgie" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </section>
      <section className="sec_img">
        <div className="container">
          <img src={pageimg} />

        </div>

      </section>
      <section className="form_width" id="Register"  ref={contactRef}>
        <div className="container-fluid">
          <div className="row  g-4">
            <div className="col-md-4">
              <div className="geo_flex">

                <img src={glogo} />
                <p>Register today to be updated on this exciting new project coming soon to Historic West Galt!

                </p>

              </div>
              <ContactUsGeorgie />
              {/* <div className="geo_form">
                <form>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <input className="form-control" type="text" placeholder="First Name*" />
                    </div>
                    <div className="col-md-6">
                      <input className="form-control" type="text" placeholder="Last Name*" />
                    </div>
                    <div className="col-md-6">
                      <input className="form-control" type="email" placeholder="Email*" />
                    </div>
                    <div className="col-md-6">
                      <input className="form-control" type="tel" placeholder="Phone*" />
                    </div>
                    <div className="col-md-12">
                      <input className="form-control" type="tel" placeholder="Subject*" />
                    </div>
                    <div className="col-md-12">
                      <textarea class="form-control" placeholder="Comments" rows="5"></textarea>

                    </div>
                    <div className="col-md-12">
                      <input type="submit" value="Send Message" />
                    </div>



                  </div>
                </form>

              </div> */}
            </div>
            <div className="col-md-4">
              <div className="geo_map">
                <iframe className="mb-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2900.849328702306!2d-80.32168772383868!3d43.3592621711176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c77b5049b9cf5%3A0xa8ceda637d0aa37d!2s16%20George%20St%20N%2C%20Cambridge%2C%20ON%20N1S%202M8%2C%20Canada!5e0!3m2!1sen!2sin!4v1702103726315!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <p><b>1 and 2 bedroom + den | modern luxury condos coming soon!</b></p>

              </div>
            </div>
            <div className="col-md-4">
              <h3>Luxury living in West Galt</h3>
              <p><b>The Georgie is located on the southwest side of Cambridge with close access to highway 24 and the 401.

              </b></p>
              <p>West Galt is a beautiful community with amazing architecture, booming real estate, and great schools. The town has a rich history and there are many century homes and historic buildings throughout.

              </p>
              <p>You’re never short on scenery with the Grand River running right through town,  dividing east and west, and there’s a fantastic balance here between the charm of a small town and the convenience of modern amenities.

              </p>
            </div>


          </div>

        </div>

      </section>
      </main>
     <GeoFooter/>
    </main>
  );
};

export default TheGeorgieWebsite;
