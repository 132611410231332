import React from "react";
import { useEffect, useRef, useState } from "react";
import "./rock.css";
import slider from "./../../images/rock/slider.webp";
import slider01 from "./../../images/rock/slider01.webp";
import logo from "./../../images/rock/logo.svg";
import home1 from "./../../images/rock/home1.webp";
import river from "./../../images/rock/river.webp";
import Camping from "./../../images/rock/Camping.svg";
import Golf from "./../../images/rock/Golf.svg";
import Fishing from "./../../images/rock/Fishing.svg";
import Picnic from "./../../images/rock/Picnic.svg";
import Canoeing from "./../../images/rock/Canoeing.svg";
import Hiking from "./../../images/rock/Hiking.svg";
import Swimming from "./../../images/rock/Swimming.svg";
import mobilefloor from "./../../images/rock/mobile-floor.webp";
import desktopfloor from "./../../images/rock/desktop-floor.webp";

import icon1 from "./../../images/rock/icon1.svg";
import icon2 from "./../../images/rock/icon2.svg";
import icon3 from "./../../images/rock/icon3.svg";
import mainLogo from "./../../images/rock/mainLogo.svg";
import icon4 from "./../../images/rock/icon4.svg";
import icon5 from "./../../images/rock/icon5.svg";
import icon6 from "./../../images/rock/icon6.svg";
import home from "./../../images/rock/home.webp";
import img01 from "./../../images/rock/img01.webp";
import img02 from "./../../images/rock/img02.webp";
import card1 from "./../../images/rock/card1.webp";
import card2 from "./../../images/rock/card2.webp";
import card3 from "./../../images/rock/card3.webp";
import card4 from "./../../images/rock/card4.webp";
import card5 from "./../../images/rock/card5.webp";
import card6 from "./../../images/rock/card6.webp";
import map from "./../../images/rock/map.webp";
import logo3 from "./../../images/rock/logo3.svg";
import logo01 from "./../../images/rock/logo01.svg";
import logo02 from "./../../images/rock/logo02.svg";
import logo03 from "./../../images/rock/logo03.svg";
import logo04 from "./../../images/rock/logo04.svg";
import projectby from "./../../images/rock/project-by.svg";

import CrescentHomes from "./../../images/rock/CrescentHomes.webp";
import mlogo from "./../../images/rock/m-logo.svg";
import leftman from "./../../images/rock/left-man.webp";
import footerlogo from "./../../images/rock/footer-logo.svg";

import rightman from "./../../images/rock/right-man.webp";
import ContactUsRock from "./ContactUsRock";


const RockHavenWebsite = () => {
  const headerRef = useRef(null);
  const [menu, setMenu] = useState('home')
  useEffect(() => {
    const toggleHeaderClass = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add("scrolled");
      } else {
        headerRef.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", toggleHeaderClass);

    return () => {
      window.removeEventListener("scroll", toggleHeaderClass);
    };
  }, []);
  const homeRef = useRef(null);
  const CityRef = useRef(null);
  const FloorPlanRef = useRef(null);
  const SiteMapRef = useRef(null);
  const FinishesRef = useRef(null);
  const AmenityRef = useRef(null);
  const BuilderRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (ref, name) => {
    const offset = 100;
    const sectionPosition =
      ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name)
  };
  return (
    <main id="rock_web">

      <header ref={headerRef}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="/"><img src={mainLogo} alt="" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#website-nav" aria-controls="website-nav" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="website-nav">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a 
                    
                   // className="nav-link active"
                   
                     href="#home"
                   onClick={(e) => {
                     e.preventDefault();
                    //  setMenu('home')
                    handleScroll(homeRef, 'home');
                   }}
                    className={`nav-link ${menu === "home" ? "activeMenu" : ""}`}
                    
                    aria-current="page" >Home</a>
                  </li>
                  <li className="nav-item">
                    <a

                      className={`nav-link ${menu === "City" ? "activeMenu" : ""}`}
                      href="#City"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(CityRef, 'City');
                      }}
                    >
                      City
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "FloorPlan" ? "activeMenu" : ""}`}
                      href="#FloorPlan"
                      onClick={(e) => {
                        e.preventDefault();

                        handleScroll(FloorPlanRef, 'FloorPlan');
                      }}
                    >
                      Floor Plan
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "SiteMap" ? "activeMenu" : ""}`}
                      href="#SiteMap"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(SiteMapRef, "SiteMap");
                      }}
                    >
                      Site Map
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "Finishes" ? "activeMenu" : ""}`}
                      href="#Finishes"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(FinishesRef, "Finishes");
                      }}
                    >
                      Finishes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "Amenity" ? "activeMenu" : ""}`}
                      href="#Amenity"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(AmenityRef, "Amenity");
                      }}
                    >
                      Amenity
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "Builder" ? "activeMenu" : ""}`}
                      href="#Builder"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(BuilderRef, "Builder");
                      }}
                    >
                      Builder
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link ${menu === "Contact" ? "activeMenu" : ""}`}
                      href="#Contact"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScroll(contactRef, "Contact");
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </div>
      </header>







      <section className="sec-padd-t">
        <div className="container">
          <h1 className="heading1 text-center mb-md-5 mb-4">
            Get ready for a <b>family
              adventure</b> of a lifetime!
          </h1>
        </div>
        <img className="d-none d-md-block w-100" src={slider} alt="" />
        <img className="d-block d-md-none w-100" src={slider01} alt="" />

        <div className="container pt-5 pb-5">
          <div className="row g-4 justify-content-between">
            <div className="col-md-8">
              <p> Welcome to <strong>RockHaven Estates</strong>, where urban conveniences blend effortlessly with the
                tranquility of nature. Nestled amidst the stunning scenery of Rockwood, RockHaven Estates offers a serene
                haven with breathtaking views.</p>

            </div>
            <div className="col-md-4 text-md-center">
              <img src={projectby} alt="" />

            </div>
          </div>
        </div>
      </section>

      <section className="mb-4">
        <div className="container">
          <div className="text-center pb-5">
            <img className="logo1" src={logo} alt="" />

          </div>
          <div className="row g-5">
            <div className="col-md-7">
              <img src={home1} alt="" />
            </div>
            <div className="col-md-5">
              <p> Where luxury, nature & exclusivity converge.
              </p>
              <p> A peaceful retreat where contemporary living meets the beautiful outdoors, nestled within the tranquil
                beauty of Rockwood, ON
              </p>
              <p> Our unique collection of custom built detached homes is designed to blend seamlessly into the stunning
                surroundings, offering spacious floor plans with luxurious finishes that create a peaceful ambiance which is
                not only relaxing but also reenergizing.</p>

              <p> Enjoy the breathtaking views of a beautiful, mature tree line from anywhere in your brand new home,
                creating a serene scenery for everyday living.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd" id="City" ref={CityRef}>
        <div className="container">
          <div className="heading-div space-b">
            <h2 className="c-heading">About the City</h2>

          </div>
          <div className="row g-md-5 g-4 m-reverse ">
            <div className="col-md-5">
              <p> Discover Rockwood, ON – where picturesque natural beauty meets modern convenience. Nestled amidst lush
                greenery, rolling hills, and breathtaking landscapes, Rockwood offers a serene escape from the hustle and
                bustle of city life while staying perfectly connected to urban centers.</p>

              <p>Strategically located near major highways, Rockwood provides easy access to nearby cities such as Guelph,
                Kitchener, and Toronto. Despite its close proximity to these urban areas, Rockwood retains its charming
                small-town ambiance, offering residents a unique blend of natural beauty and contemporary amenities.</p>

              <p>Enjoy hiking along scenic trails, engaging in water activities on the Eramosa River, or simply soaking in
                the stunning views. Rockwood Township is the ideal retreat for those seeking a harmonious connection with
                nature in a tranquil, idyllic setting. Experience the best of both worlds in Rockwood – where serenity and
                accessibility come together perfectly.</p>
            </div>
            <div className="col-md-7">
              <img src={river} alt="" />
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <h3 className="s-title pb-4 pt-4">
                Things to do in Rockwood
              </h3>
              <ul className="c-row">
                <li><img src={Camping} alt="" /><span>Camping</span></li>
                <li><img src={Golf} alt="" /><span>Golf</span></li>
                <li><img src={Fishing} alt="" /><span>Fishing</span></li>
                <li><img src={Picnic} alt="" /><span>Picnic</span></li>
                <li><img src={Canoeing} alt="" /><span>Canoeing</span></li>
                <li><img src={Hiking} alt="" /><span>Hiking</span></li>
                <li><img src={Swimming} alt="" /><span>Swimming</span></li>

              </ul>
            </div>
          </div>
        </div>

      </section>
      <section className="sec-padd-b mt-4" id="FloorPlan" ref={FloorPlanRef}>
        <div className="container">
          <div className="heading-div space-b">
            <h2 className="c-heading">Floor Plans</h2>
          </div>
          <div className="row g-4 justify-content-between pb-4">
            <div className="col-lg-7 col-md-6">
              <h3 className="m-heading">Timber View</h3>
              <p>Spacious floor plan with 9' ceilings & hardwood flooring on the main floor living area, gourmet kitchen,
                luxurious bathrooms, and ample natural light. Perfect for modern living.</p>
            </div>
            <div className="col-lg-5 col-md-6 text-md-end">
              <div className="d-flex gap-3 justify-content-end">
                <a href="https://drive.google.com/file/d/1blFuefyDwBj3ANgf2MQvS6tIK7Nh9_Sc/view?usp=sharing" target="_blank" className="c-btn brown-btn" rel="noopener">Download Floor Plan</a>
                <a href="https://rockhavenestates.ca/wp-content/uploads/2024/07/RockHaven-Brochure.pdf" target="_blank" className="c-btn brown-btn" rel="noopener">Download Brochure</a>
                <div>
                </div>
              </div>
            </div>
          </div>
          <picture>
            <source media="(min-width:767px)" srcSet={desktopfloor} />
            <img src={mobilefloor} alt="Flowers" />
          </picture>


          <div className="icon-group">
            <div className="row g-4 align-items-center">
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon1} alt="" />
                  <div className="icon-content">
                    <h3>3,000</h3>
                    <p>Sq. Ft.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon2} alt="" />
                  <div className="icon-content">
                    <h3>4</h3>
                    <p>Beds</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon3} alt="" />
                  <div className="icon-content">
                    <h3>3.5</h3>
                    <p>Bath</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon4} alt="" />
                  <div className="icon-content">
                    <h3>2</h3>
                    <p>Car Garage</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon5} alt="" />
                  <div className="icon-content">
                    <h3>46 <sub>ft.</sub></h3>
                    <p>Lot Front</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="icon-with">
                  <img src={icon6} alt="" />
                  <div className="icon-content">
                    <h3>181 <sub>ft.</sub></h3>
                    <p>Lot Depth</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>

      <section className="sec-padd-b mt-4" id="SiteMap" ref={SiteMapRef}>
        <div className="container">
          <div className="bg-pattern">
            <div className="heading-div space-b">
              <h2 className="c-heading">Site Map</h2>
            </div>
            <img src={home} alt="" />

          </div>
        </div>

      </section>

      <section className="sec-padd-b mt-4 feature_sec" id="Finishes" ref={FinishesRef}>
        <div className="container">
          <div className="heading-div space-b">
            <h2 className="c-heading">Features & Finishes</h2>

          </div>
          <div className="row g-5">
            <div className="col-md-7">
              <img src={img01} alt="" />
            </div>
            <div className="col-md-5">
              <h3 className="m-heading">Exterior Features</h3>
              <ul>
                <li>Architecturally designed exteriors with quality clay brick and partial stone fronts.</li>
                <li>Vinyl siding on second-floor elevations with colour-matched trim.</li>
                <li>Pre-finished aluminum soffits, fascia, eavestroughs, and downspouts.</li>
                <li>Energy Star low-e argon gas filled vinyl clad windows.</li>
                <li>Maintenance-free vinyl slider windows in the basement.</li>
                <li>Insulated patio or terrace door, fiberglass insulated front entry door.</li>
                <li>Concrete front porch, aluminum railings as required.</li>
                <li>Graded lot with sod, finished asphalt driveway, address stone above garage.</li>
                <li>Parged exterior foundation walls.</li>
                <li>Satin chrome grip set and deadbolt. (Selecation available)</li>
              </ul>
            </div>
            <div className="col-md-12">
              <p>All drawings are an artist's concept and may vary slightly from the final product. E. & O.E. Actual usable
                floor space may vary from stated area, and square footage has been calculated based on usable living space
                according to the Tarion rules. Drawings may show optional features which may not be included in the base
                price. Bulkheads and box out may be required as chases for plumbing and mechanical. Location of mechanical
                room equipment will be located by the builder and may change based on plan. Number of steps in from entry
                may vary based on grade.</p>
            </div>


          </div>
          <div className="row g-5 m-reverse ">

            <div className="col-md-5">
              <h3 className="m-heading">Interior Features</h3>
              <ul>
                <li>Impressive 9’ ceilings on main floor and 8’ on second level except in areas where architectural details,
                  mechanical or ductwork require ceiling height to be lowered.</li>
                <li>Wooden railings with wooden spindles.</li>
                <li>Oak stained hardwood from main to second floor.</li>
                <li>Flat ceilings throughout.</li>
                <li>Interior walls to be painted with one coat tinted primer and two finish coats of quality flat low voc
                  latex paint.</li>
                <li>All interior doors and trims finished in white, semi-gloss low voc latex paint.</li>
                <li>White two-panel smooth finish doors with colour matching swing closet doors, as per plan.</li>
                <li>Satin chrome interior door hardware. (Selecation available)</li>
                <li>5 ¼” baseboard and 2 ¾” trim and casing.</li>
                <li>All models feature convenient second floor laundry.</li>
              </ul>
            </div>
            <div className="col-md-7">
              <img src={img02} alt="" />
            </div>


          </div>
        </div>

      </section>
      <section className="section-card sec-padd-b">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card1} alt="" />
                <div className="c-body">
                  <h3>GOURMET KITCHEN</h3>
                  <ul>
                    <li> Contemporary cabinetry from builder’s selections. </li>
                    <li>Double stainless-steel undermount kitchen sink with single lever faucet.</li>
                    <li>Luxury stone kitchen countertops from builder’s selections.</li>
                    <li>60” x 36” Kitchen island.</li>
                    <li>2 speed stainless steel exhaust fan over stove vented to the exterior.</li>
                    <li>Two USB charging outlets included.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card2} alt="" />
                <div className="c-body">
                  <h3>bathroom</h3>
                  <ul>
                    <li>Main and ensuite bathrooms include cabinetry with undermount sinks with choice of laminated
                      countertop
                      from builder’s selections.</li>
                    <li>Large mirrors above vanities.</li>
                    <li>White acrylic tub in main bath with tile surround to ceiling.
                      Ensuite bath with freestanding Soker Tub.</li>
                    <li>Ensuite shower features full tile surround, glass door and tiled base.</li>
                    <li>Double vanity in ensuite bath.</li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card3} alt="" />
                <div className="c-body">
                  <h3>Flooring</h3>
                  <ul>
                    <li>Premium broadloom carpet over heavy duty under-pad in bedrooms and upper hall from builders
                      selections.</li>
                    <li>Kitchen, bathrooms, front hall, foyer and laundry room (either main floor or 2nd floor) to be
                      finished
                      in ceramic tile from builder’s samples.</li>
                    <li>Carpet free main floor with hardwood floor in living areas.</li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card4} alt="" />
                <div className="c-body">
                  <h3>GARAGE</h3>
                  <ul>
                    <li>Poured concrete floor.</li>
                    <li>Garage with spray foam insulation below all living spaces.</li>
                    <li>Metal sectional, insulated, roll-up garage door with windows and rust-resistant hardware.</li>
                    <li>Metal insulated door from garage to house with hinged self closer, as grade permits.</li>
                    <li>Rough-in and receptacle for future garage door openers.
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card5} alt="" />
                <div className="c-body">
                  <h3>GARAGE</h3>
                  <ul>
                    <li>Poured concrete basement walls and floors.</li>
                    <li>Basement exterior walls covered with dimple wrap for waterproofing.</li>
                    <li>Rough-in for 3-piece bathroom in basement.</li>
                    <h3>INSULATION</h3>
                    <li>Energy-saving insulation as per Ontario building code (as amended from time to time).</li>
                    <li>R60 attic insulation.</li>
                    <li>R60 attiR24 exterior walls.</li>
                    <li>R20 basement insulation wrap.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="c-card">
                <img src={card6} alt="" />
                <div className="c-body">
                  <h3>PLUMBING</h3>
                  <ul>
                    <li>2 exterior frost proof water taps (one in garage and one at the rear of the house). </li>
                    <li>Water shut off valves under all sinks.</li>
                    <li>Stylish faucets & American standard fixtures throughout.</li>
                    <li>Single white plastic laundry tub in laundry room.</li>
                    <li>Floor drain in mechanical room.
                    </li>
                    <li>Temperature and pressure control valve in all bathrooms.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p>All renderings & Images are artist’s concept and may vary from the final product. Rendering includes lighting,
            furniture and decor that is not included E. & O. E.</p>
        </div>

      </section>

      <section className="mt-4 map_sec"  id="Amenity" ref={AmenityRef}>
        <div className="container">
          <div className="heading-div space-b">
            <h2 className="c-heading">Amenity Map</h2>
          </div>
          <p className="pt-2 pb-4"> Rockwood is a vibrant and attractive place to live, work, and visit! It is conveniently situated on​ Highway 7 between Acton and City of Guelph, facilitating easy access to nearby cities such as Guelph, Milton, Georgetown and Mississauga.​ Additionally, the proximity to HWY 401 and major cities makes it an​ ideal location for those seeking a balance between rural tranquillity and urban convenience.</p>

        </div>
      </section>

      <section className="sec-padd-b map-bg">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-5">
              <div className="flex-time">
                <h3>13</h3>
                <div className="right-side">
                  <p>MINUTES TO</p>
                  <h4>U of Guelph</h4>
                </div>
              </div>
              <div className="flex-time">
                <h3>15</h3>
                <div className="right-side">
                  <p>MINUTES TO</p>
                  <h4>DT Guelph</h4>
                </div>
              </div>
              <div className="flex-time">
                <h3>18</h3>
                <div className="right-side">
                  <p>MINUTES TO</p>
                  <h4>HWY 401</h4>
                </div>
              </div>
              <div className="flex-time">
                <h3>30</h3>
                <div className="right-side">
                  <p>MINUTES TO</p>
                  <h4>Mississauga</h4>
                </div>
              </div>
              <div className="flex-time">
                <h3>47</h3>
                <div className="right-side">
                  <p>MINUTES TO</p>
                  <h4>Pearson INT Airport</h4>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <img src={map} alt="" />
            </div>
          </div>
        </div>

      </section>


      <section className="mt-4 builder-sec sec-padd"  id="Builder" ref={BuilderRef}>
        <div className="container">
          <div className="heading-div space-b">
            <h2 className="c-heading">Know the Builder</h2>
          </div>
          <img src={CrescentHomes} alt="" />
          <p> At Crescent Homes, we believe in enhancing the quality of life. We offer prime locations, innovative products, excellent customer service, and competitive pricing. A qualified team meticulously crafting every home, ensuring attention to detail and customer satisfaction. We prioritize energy efficiency, health, and comfort in our homes, tailoring each build to meet the unique needs of every buyer.</p>
          <p>Crescent Homes' commitment extends beyond construction; we strive to foster trust, built strong relationships, and attain peace of mind for our buyers. Our knowledgeable and passionate team collaborates closely with buyers throughout the process to make their vision a reality. We strive to uphold the values of innovation, integrity, and excellence in everything we do, providing employees with a supportive work environment in which they can deliver exceptional craftsmanship and service while maintaining professional integrity.</p>
          <p>Our mission is to provide customers with high-quality, dependable homes that they will be proud of for years. We value professionalism, integrity, and fairness in all of our interactions.</p>

        </div>
      </section>
      <section className="footer-form sec-padd-b" id="Contact" ref={contactRef}>
        <div className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-md-4">
              <p className="text-center">More Projects by <b>Crescent Homes</b></p>
              <ul>
                <li><img src={logo01} alt="" /></li>
                <li><img src={logo02} alt="" /></li>
                <li><img src={logo03} alt="" /></li>
                <li><img src={logo04} alt="" /></li>

              </ul>
            </div>
            <ContactUsRock  contactRef={contactRef} id="Contact" />
            {/* <div className="col-md-7">
              <p><b>Request a Call Back same</b></p>
              <form action="">
                <div className="row g-4">
                  <div className="col-md-6">
                    <input type="text" name="" id="" placeholder="First Name" />
                  </div>
                  <div className="col-md-6">
                    <input type="text" name="" id="" placeholder="Last Name" />
                  </div>
                  <div className="col-md-12">
                    <input type="email" name="" id="" placeholder="Email" />
                  </div>
                  <div className="col-md-12">
                    <input type="tel" name="" id="" placeholder="Phone" />
                  </div>
                  <div className="col-md-12">
                    <textarea name="" id="" cols="30" rows="5" placeholder="Your Message"></textarea>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="">Are you currently working with a Realtor?</label>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio" id="radio1" />
                      <label className="form-check-label pt-0" htmlFor="radio1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio" id="radio2" checked />
                      <label className="form-check-label pt-0" htmlFor="radio2">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="">Are you a realtor?</label>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="realtor" id="radio3" />
                      <label className="form-check-label pt-0" htmlFor="radio3">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="realtor" id="radio4" checked />
                      <label className="form-check-label pt-0" htmlFor="radio4">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-primary">Submit Now</button>
                  </div>
                </div>
              </form>
            </div> */}

          </div>
        </div>
      </section>

      <footer className="sec-padd-t">
        <div className="container">
          <div className="row g-4 ">
            <div className="col-md-7 m-auto sec-padd-b">
              <h2 className="c-heading3 text-center mb-4">Exclusive Listing Brokerage</h2>
              <h3 className="sub-heading text-center">RE/MAX Real Estate Centre Inc., Brokerage</h3>
              <div className="m-show footer-logo-div">
                <img src={mlogo} alt="" />
                <p>amitairi.com</p>
              </div>
            </div>
            <div className="footer-info">

              <div className="col-md-7 m-auto" >
                <div className="row g-4">
                  <div className="col-md-4">
                    <div className="group-1">
                      <img src={leftman} alt="" />
                      <div className="info-details">
                        <h2>Amit Airi</h2>
                        <p>Broker</p>
                        <h3>519-623-0000</h3>
                        <h3>info@amitairi.com</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="group-m">
                      <img className="position-relative" src={footerlogo} alt="" />
                      <h3>amitairi.com</h3>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="group-2">
                      <div className="info-details">
                        <h2>Ashish Wadhwa</h2>
                        <p>Realtor®</p>
                        <h3>519-221-3601</h3>
                        <h3>ashish@amitairi.com</h3>
                      </div>
                      <img src={rightman} alt="" />
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <p className="text-center pt-4 pb-4 mb-0">Disclaimer: Renderings shown are artist’s concept and may include upgraded, unavailable, or furniture features. Interior & exterior design used for illustrative purposes only. See list of included features for details or speak with our sales representative for more details E.A.O.E.</p>
        </div>
      </footer>
    </main>
  );
};

export default RockHavenWebsite;
