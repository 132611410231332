import React, { useState, useEffect } from 'react';
import "./geo.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import logo from "./../../images/thegeorgie/TheGeorgie_logo.webp";
import logo2 from "./../../images/thegeorgie/logo2.svg";
import logo3 from "./../../images/thegeorgie/logo3.svg";

function GeoHeader(props) {
    const [headerClass, setHeaderClass] = useState('');
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setHeaderClass('scrolled');
        } else {
          setHeaderClass('');
        }
      };

      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <>
      <div className="top-header">
        <div className="container">
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/TheGeorgie2023"><FaFacebookF /></a></li>
            <li><a target="_blank" href="https://www.instagram.com/TheGeorgie2023"><FaInstagram /></a></li>
            <li><a target="_self" class="header-btn" href="https://thegeorgie.ca/wp-content/uploads/2023/12/TheGeorgie_Book_WEB.pdf">DOWNLOAD BROCHURE</a></li>

          </ul>
        </div>
      </div>
      <header className={`mainheader ${headerClass}`}>
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <a className="navbar-brand" href="/"><img src={
               props?.logoWhite ? logo2 :
              logo3} alt=""  style={{height:'70px'}}/></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                  className={`nav-link ${
                    props?.menuName === "Features" ? "activeMenu" : ""
                  }`}
  
                  
                  aria-current="page" href="/communities/thegeorgie/features-finishes">Features + Finishes</a>
                </li>
                <li class="nav-item">
                  <a className={`nav-link ${
                    props?.menuName === "Floorplans" ? "activeMenu" : ""
                  }`}
                  
                   href="/communities/thegeorgie/floorplans">Floorplans</a>
                </li>
                <li class="nav-item">
                  <a className={`nav-link ${
                    props?.menuName === "Gallery" ? "activeMenu" : ""
                  }`} href="/communities/thegeorgie/the-gallery">Gallery</a>
                </li>
                <li class="nav-item">
                  <a className={`nav-link ${
                    props?.menuName === "Builders" ? "activeMenu" : ""
                  }`} href="/communities/thegeorgie/the-builder"
                  
                  >The Builder</a>
                </li>
              {   props?.logoWhite && <li class="nav-item">
                  <a class="nav-link" href="#Register"
                   onClick={(e) => {
                    e.preventDefault();
                    props?.handlePage(props?.contactRef, "Register");
                  }}
                  >Register</a>
                </li>}

              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default GeoHeader