import React from 'react'
import GeoHeader from './geoHeader'
import GeoFooter from './geoFooter'
import top from "./../../images/thegeorgie/icon-top.webp";
import bottom from "./../../images/thegeorgie/icon-bottom.webp";
import { FaBookOpenReader } from "react-icons/fa6";

import family from "./../../images/thegeorgie/family.webp";


function TheBuilder() {
    return (
        <>
            <main id="thegeorgie" className='inner_header'>
                <GeoHeader  logoWhite={false}  
                  menuName="Builders"/>
                <main>
                    <section className='geo-banner geo-page-banner'>
                        <div className='container'>
                            <div className='geo-banner__content'>
                                <img className='top_icon' src={top} />
                                <h1>Gallery</h1>
                                <img className='bottom_icon' src={bottom} />
                            </div>
                        </div>


                    </section>

                    <section className='sec-padd features-page'>
                        <div className='container'>
                            <div className='row g-md-5 g-4'>
                                <div className='col-md-6'>
                                    <h2 className='mb-4'>Crescent Homes believes in creating a quality life.</h2>
                                    <p>Crescent Homes is dedicated to fostering a high-quality lifestyle. Our commitment is evident through our exceptional locations, innovative products, outstanding customer service, and unbeatable prices in the market.  </p>
                                    <p>Our team collaborates closely with every buyer to guarantee that the home is constructed according to their preferences and perfectly meets their family’s requirements. We are firmly dedicated to the process of building.</p>
                                    <p>It goes beyond constructing new homes; we also prioritize building trust, relationships, and providing peace of mind. We possess extensive knowledge and genuine enthusiasm for every facet of our work. Our goal is to effectively communicate this knowledge and passion to home buyers, ensuring they are well-informed and inspired.</p>
                                    <p>We know how important your new home is to you; that’s why each home we build is so important to us.</p>
                                    <div className='text-center pt-5'>
                                        <a class="outline_btn" href="/">
                                            Learn More <FaBookOpenReader />

                                            	</a>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <img src={family} />
                                </div>



                            </div>

                        </div>

                    </section>






                </main>

                <GeoFooter />
            </main>
        </>
    )
}

export default TheBuilder