import React from 'react'
import CrescentHomes from "./../../images/thegeorgie/CrescentHomes.webp";
import glogo from "./../../images/thegeorgie/TheGeorgie_WEBL.webp";
function GeoFooter() {
  return (
    <>
         <section className="geo_footer_main">
      <div className="geo_footer">
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-md-4">
            <img src={CrescentHomes} />
            </div>
            <div className="col-md-4">
            <img src={glogo} />
            </div>
            <div className="col-md-4">
                   <h5>The Georgie</h5>
                   <p>16-20 GEORGE STREET, NORTH CAMBRIDGE, ON N1S 2M8

</p>
            </div>
          </div>
        </div>

      </div>

      <div className="geo_footer_menu">
        <div className="container">
        <div class="copyright-wrap">
          <div class="footer-menu-wrap">
            <div class="footer-menu-container">
              <ul class="footer-menu">
                <li><a href="/communities/thegeorgie/features-finishes">Features + Finishes</a></li>
                <li><a href="/communities/thegeorgie/floorplans">Floorplans</a></li>
                <li><a href="/communities/thegeorgie/the-gallery">Gallery</a></li>
                <li><a href="/communities/thegeorgie/the-builder">The Builder</a></li>
                <li><a href="#register">Register</a></li>
              </ul>
              </div>
              </div>
          <div class="copyright">© 2023 The Georgie. All Rights Reserved by <a target="_blank" rel="nofollow" href="https://www.thegeorgie.ca/">The Georgie</a></div>
        </div>
      </div>
      </div>
      </section>
    </>
  )
}

export default GeoFooter