import React from 'react'
import GeoHeader from './geoHeader'
import GeoFooter from './geoFooter'
import top from "./../../images/thegeorgie/icon-top.webp";
import bottom from "./../../images/thegeorgie/icon-bottom.webp";

function FeaturesFinishes() {
    return (
        <>
            <main id="thegeorgie" className='inner_header'>
                <GeoHeader  
                 logoWhite={false}
                 menuName="Features"
                />
                <main>
                    <section className='geo-banner'>
                        <div className='container'>
                            <div className='geo-banner__content'>
                                <img className='top_icon' src={top} />
                                <h1>Features & Finishes</h1>
                                <img className='bottom_icon' src={bottom} />
                            </div>
                        </div>


                    </section>

                    <section className='sec-padd features-page'>
                        <div className='container'>
                            <div className='row g-md-5 g-4'>
                                <div className='col-md-4'>
                                    <h2>Features + Finishes</h2>
                                </div>
                                <div className='col-md-8'>
                                    <h5>The Building</h5>
                                    <ul>
                                        <li>New 5 story building architecturally designed to blend well with existing neighborhood</li>
                                        <li>Concrete patio as per applicable plan</li>
                                        <li>All windows are high efficiency Low E Argon gas filled maintenance free vinyl clad windows</li>
                                        <li>Insulated patio door, as per applicable plan</li>
                                        <li>Rooftop amenity area with panoramic views of Historic Galt</li>
                                        <li>One parking space included in heated and secured underground garage, with bicycle parking and elevator access</li>
                                        <li>Smart device compatible security entrance</li>
                                        <li>Designer lobby with convenient elevator</li>
                                        <li>Convenient commercial storefront on ground floor of building</li>
                                        <li>Unique property built into natural landscape with trees and changing elevations</li>

                                    </ul>

                                    <h5>Kitchen & Bath</h5>
                                    <ul>
                                        <li>Quality kitchen and bathroom cabinets from Builder’s selections</li>
                                        <li>Stone countertops throughout with under mount sinks</li>
                                        <li>Opening in cabinetry for future dishwasher and rough-in provided.</li>
                                        <li>Soft close cabinet doors and drawers</li>
                                        <li>Two pots and pans drawers</li>
                                        <li>Stainless steel slim microwave/vent hood, vented to the exterior</li>
                                        <li>Large mirrors above vanities</li>
                                        <li>Quality white plumbing fixtures with designer brushed nickel faucets (low water consumption toilet supplied with insulated tank)</li>
                                        <li>White acrylic 1 piece tub with tile surround for easy maintenance</li>
                                        <li>Units with ensuite baths feature tiled showers with glass doors and tile base</li>
                                        <li>Extra quiet exhaust fan vented to the exterior in all bathrooms and powder rooms</li>

                                    </ul>

                                    <h5>Interior Features</h5>
                                    <ul>
                                        <li>Generous sized windows with lots of natural light</li>
                                        <li>Brushed Nickel interior door hardware. 2-Panel interior doors with color matching closet doors, as per applicable plan painted semi-gloss white.</li>
                                        <li>Deadbolts on all entry doors</li>
                                        <li>Smooth ceilings throughout</li>
                                        <li>Interior walls to be painted with one coat tinted primer and one finish coat of quality flat latex paint. Paint colour from Builder’s samples. One paint colour throughout.</li>
                                        <li>Carpet free, high quality luxury vinyl plank and tile from builders selections throughout including bedrooms</li>
                                        <li>All units feature in-suite laundry</li>

                                    </ul>

                                    <h5>Electrical & Mechanical Features</h5>
                                    <ul>
                                        <li>100 AMP service with circuit breaker panel and copper wire throughout</li>
                                        <li>LED potlights throughout kitchen, hallways, and all living spaces</li>
                                        <li>USB outlet in kitchen</li>
                                        <li>Dedicated electrical outlet for refrigerator.</li>
                                        <li>Electrical outlets at counter level for small appliances</li>
                                        <li>Heavy duty stove and dryer connections</li>
                                        <li>A total of 2 outlets to be used in any combination of telephone or cable outlets</li>
                                        <li>1 exterior weather proof receptacle on private amenity connected to a Ground Fault Interrupt safety switch, as per applicable plan</li>
                                        <li>Energy efficient heating and air conditioning with in-suite controls</li>
                                        <li>Natural Gas hot water heater provided, supplied by Builder. Rental contract to be set up by purchaser.</li>

                                    </ul>


                                </div>
                            </div>

                        </div>

                    </section>






                </main>

                <GeoFooter />
            </main>
        </>
    )
}

export default FeaturesFinishes