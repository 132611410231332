import React from 'react'
import GeoHeader from './geoHeader'
import GeoFooter from './geoFooter'
import top from "./../../images/thegeorgie/icon-top.webp";
import bottom from "./../../images/thegeorgie/icon-bottom.webp";
import Accordion from 'react-bootstrap/Accordion';

function TheGallery() {
    return (
        <>
            <main id="thegeorgie" className='inner_header'>
                <GeoHeader  logoWhite={false}  menuName="Gallery"/>
                <main>
                    <section className='geo-banner geo-page-banner'>
                        <div className='container'>
                            <div className='geo-banner__content'>
                                <img className='top_icon' src={top} />
                                <h1>Gallery</h1>
                                <img className='bottom_icon' src={bottom} />
                            </div>
                        </div>


                    </section>

                    <section className='sec-padd features-page'>
                        <div className='container'>
                            <div className='row g-md-5 g-4'>
                                <div className='col-md-10 m-auto'>
                                    <h2 className='mb-4'>An up-and-comer in Cambridge, the Gaslight District is in the process of revitalization.</h2>
                                    <p>Characterized by historic buildings, this urban neighbourhood features beautiful stonework, multi-unit residences and plenty of history. Bordering the Grand River, there are lovely places to walk and explore. Take a tour through the Cambridge Sculpture Garden or the McDougall Cottage Historic Site. Enjoy some community drama at the Hamilton Family Theatre, or cross over the river to go into Downtown Galt. With plenty of renewal in the works, this neighbourhood is full of potential for those who love historic architecture, an urban atmosphere and proximity to downtown.

                                    </p>
                                </div>
                                <div className='col-md-10 m-auto pt-5'>
                                  <div className='g-4 g-md-5 row pb-4'>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Dining_o_1.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_unitA1_2.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_5.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_BONUS_6.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_unitA1_3.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_3.jpg"/>
                                    </div>

                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_4.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_unitA1_1-1.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_1.jpg"/>
                                    </div>

                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_unitA1_4.jpg"/>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                       <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/Render_F_2.jpg"/>
                                    </div>



                                  </div>

                                </div>
                                <div className='col-md-10 m-auto pt-5'>
                                <img src="https://www.thegeorgie.ca/wp-content/uploads/2023/12/DTGaltIMG_7867-66.jpg"/>
                                </div>

                            </div>

                        </div>

                    </section>






                </main>

                <GeoFooter />
            </main>
        </>
    )
}

export default TheGallery